@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
@font-face {
  font-family: "Noto Sans JP";
  src: url("../fonts/NotoSansCJKjp-Regular.woff");
  font-weight: normal; }

@font-face {
  font-family: "Noto Sans JP";
  src: url("../fonts/NotoSansCJKjp-Medium.woff");
  font-weight: 500; }

@font-face {
  font-family: "Noto Sans JP";
  src: url("../fonts/NotoSansCJKjp-Bold.woff");
  font-weight: bold; }

@font-face {
  font-family: "bahnschrift";
  src: url("../fonts/BAHNSCHRIFT 1.ttf");
  font-weight: 400; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

main {
  display: block; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none; }

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Noto Sans JP", sans-serif;
  box-sizing: border-box; }
  @media (max-width: 1400px) {
    html {
      font-size: 58%; } }
  @media (max-width: 1200px) {
    html {
      font-size: 54%; } }
  @media (max-width: 768px) {
    html {
      font-size: 1.333vw; } }

body {
  color: #484747;
  letter-spacing: 0.2em;
  line-height: 1.5; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

img {
  max-width: 100%;
  width: 100%;
  line-height: 1;
  vertical-align: bottom; }

button {
  outline: none; }

input {
  outline: none; }

textarea {
  outline: none;
  resize: none; }

/* Components */
.body--modal-active {
  overflow: hidden; }

.wrap {
  position: relative;
  min-width: 1200px; }
  @media (max-width: 768px) {
    .wrap {
      min-width: 100%; } }

.main {
  padding-top: 11rem;
  transition: all 0.3s; }
  @media (max-width: 768px) {
    .main {
      padding-top: 0; } }

.header--sticky.main {
  padding-top: 5.5rem; }
  @media (max-width: 768px) {
    .header--sticky.main {
      padding-top: 0; } }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: bold;
  border: 2px solid #f0844b;
  transition: all 0.3s; }
  @media (max-width: 768px) {
    .btn {
      font-size: 2.8rem; } }
  .btn--header {
    background-color: #f0844b;
    color: #fff;
    width: 13.7rem;
    height: 100%; }
    .btn--header:hover {
      background-color: #fff;
      color: #f0844b; }
  .btn--footer {
    height: 7.4rem;
    border-radius: 100px;
    background-color: #f0844b;
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 0.06em; }
    .btn--footer::before {
      font-family: "Font Awesome 5 Free";
      content: "\f0e0";
      font-weight: bold;
      margin-right: 1.6rem;
      transform: translateY(0.2rem); }
    .btn--footer:hover {
      color: #f0844b;
      background-color: #fff; }
  .btn--border {
    background-color: #fff;
    border-radius: 4px;
    color: #f0844b;
    width: 100%;
    height: 100%; }
    .btn--border:hover {
      background-color: #f0844b;
      color: #fff; }

.container {
  width: 100%;
  max-width: 166rem;
  padding: 0 3rem;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .container {
      padding: 0 4rem; } }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(240, 132, 75, 0.5);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  padding: 10rem 3rem;
  overflow-y: scroll;
  cursor: pointer;
  transition: opacity 0.6s;
  opacity: 0;
  visibility: hidden; }
  .modal.is-active {
    z-index: 100;
    opacity: 1;
    visibility: visible; }
  .modal__content {
    position: relative;
    background-color: #fff;
    width: 100%;
    max-width: 96rem;
    padding: 5.6rem 6rem 6rem;
    border-radius: 1rem;
    margin: 0 auto;
    cursor: auto; }
    @media (max-width: 768px) {
      .modal__content {
        padding: 6rem 4rem 6rem; } }
  .modal__close {
    width: 4.8rem;
    height: 4.8rem;
    transform: rotate(45deg);
    position: absolute;
    top: 3.1rem;
    right: 3.1rem;
    cursor: pointer;
    display: block;
    z-index: 1; }
    .modal__close::before, .modal__close::after {
      content: "";
      background-color: #898989;
      display: block;
      position: absolute;
      border-radius: 4px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    .modal__close::before {
      width: 2px;
      height: 100%; }
    .modal__close::after {
      width: 100%;
      height: 2px; }
  .modal__title {
    position: relative;
    font-size: 2.4rem;
    font-weight: bold;
    padding-left: 2.1rem; }
    @media (max-width: 768px) {
      .modal__title {
        font-size: 4rem;
        padding-left: 2.3rem; } }
    .modal__title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-color: #f0844b;
      width: 0.6rem;
      height: 100%;
      border-radius: 12px; }
  .modal__secondary-title {
    color: #f0844b;
    font-size: 2.4rem;
    font-weight: bold;
    margin-top: 3.9rem; }
    @media (max-width: 768px) {
      .modal__secondary-title {
        font-size: 4rem; } }
  .modal__paragraph {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 0.8rem; }
    @media (max-width: 768px) {
      .modal__paragraph {
        font-size: 2.8rem; } }
  .modal__cols {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem; }
    @media (max-width: 768px) {
      .modal__cols {
        flex-wrap: wrap;
        margin-top: 6rem; } }
  @media (max-width: 768px) {
    .modal__col:not(:first-child) {
      margin-top: 4rem; } }
  .modal__col-text {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 1.5rem; }
    @media (max-width: 768px) {
      .modal__col-text {
        font-size: 2.4rem; } }
  .modal__2cols .modal__col {
    width: calc(50% - 2rem); }
    @media (max-width: 768px) {
      .modal__2cols .modal__col {
        width: 100%; } }
  .modal__3cols .modal__col {
    width: calc(33.3% - 1.7rem); }
    @media (max-width: 768px) {
      .modal__3cols .modal__col {
        width: 100%; } }
  .modal__video-wrap {
    max-width: 64rem;
    width: 100%;
    margin: 4.9rem auto 0; }
  .modal__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%; }
  .modal__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video .modal__content {
  padding-bottom: 7.5rem; }

.modal-video .modal__video-wrap + * {
  margin-top: 6rem; }

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  background-color: #fff;
  padding: 9.5rem 0 3.4rem;
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
  pointer-events: none; }
  .menu.is-active {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0); }
  @media (max-width: 768px) {
    .menu {
      transform: translateY(100%);
      top: auto;
      bottom: 0;
      padding: 5.5rem 4.8rem 18rem;
      box-shadow: 0 -0.4rem 0.8rem rgba(0, 0, 0, 0.1); } }
  .menu--pt {
    padding-top: 15rem; }
    @media (max-width: 768px) {
      .menu--pt {
        padding-top: 5.5rem; } }
  .menu__content {
    margin: 0 auto;
    max-width: 109rem;
    padding: 0 4.4rem;
    visibility: visible;
    transition: opacity 0.3s;
    opacity: 1;
    height: auto;
    pointer-events: auto; }
    @media (max-width: 768px) {
      .menu__content {
        padding: 0; } }
  .menu__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .menu__list {
        justify-content: space-between;
        flex-wrap: wrap; } }
  .menu__item {
    position: relative;
    width: 25%; }
    @media (max-width: 768px) {
      .menu__item {
        width: 50%;
        max-width: 30rem; } }
    .menu__item:nth-child(n + 5) {
      margin-top: 4rem; }
      @media (max-width: 768px) {
        .menu__item:nth-child(n + 5) {
          margin-top: 0; } }
    @media (max-width: 768px) {
      .menu__item:nth-child(n + 3) {
        margin-top: 4.3rem; } }
  .menu__item-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .menu__item-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    pointer-events: auto; }
  .menu__item-img {
    width: 100%;
    height: 19.7rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .menu__item-img img {
      height: 100%;
      width: auto; }
    @media (max-width: 768px) {
      .menu__item-img {
        height: 27.2rem; } }
  .menu__item-name {
    font-family: "Quicksand", "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    letter-spacing: 0.1em;
    line-height: 1;
    margin-top: 2.2rem; }
    @media (max-width: 768px) {
      .menu__item-name {
        font-size: 3.6rem; } }
  .menu__item-text {
    color: #b2b2b2;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.66;
    margin-top: 1.2rem; }
    @media (max-width: 768px) {
      .menu__item-text {
        font-size: 2.4rem;
        line-height: 1.33; } }
  .menu__item-btn {
    width: 16rem;
    height: 4rem;
    margin: 1.6rem auto 0; }
    @media (max-width: 768px) {
      .menu__item-btn {
        width: 100%;
        height: 7rem;
        margin-top: 2.8rem; } }
    .menu__item-btn .btn {
      font-size: 1.2rem; }
      @media (max-width: 768px) {
        .menu__item-btn .btn {
          font-size: 2.4rem; } }
  .menu__list--type02 {
    pointer-events: none; }
    .menu__list--type02 .menu__item-img {
      height: 17.6rem; }
      @media (max-width: 768px) {
        .menu__list--type02 .menu__item-img {
          height: 30.7rem; } }
      .menu__list--type02 .menu__item-img img {
        height: 100%;
        width: auto; }
    .menu__list--type02 .menu__item-btn {
      pointer-events: auto; }
      .menu__list--type02 .menu__item-btn .btn {
        pointer-events: auto; }
    .menu__list--type02 .menu__item-img {
      align-items: flex-end; }

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  .menu-overlay.is-active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    cursor: pointer; }

.sp-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  height: 12.5rem;
  width: 100%;
  box-shadow: 0 -0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none; }
  @media (max-width: 768px) {
    .sp-nav {
      display: block; } }
  .sp-nav__list {
    height: 100%;
    display: flex;
    justify-content: space-between; }
  .sp-nav__item {
    width: 16.66%;
    height: 100%;
    border-left: 1px solid #b9b9b9; }
    .sp-nav__item:first-child {
      border-left: 0; }
  .sp-nav__btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.7rem; }
    .sp-nav__btn .fas {
      color: #f0844b;
      font-size: 3.5rem;
      margin: auto 0 0 0;
      pointer-events: none; }
  .sp-nav__btn.is-active {
    background-color: #f0844b; }
    .sp-nav__btn.is-active .sp-nav__btn-text,
    .sp-nav__btn.is-active .fas {
      color: #fff; }
  .sp-nav__btn-text {
    font-size: 1.8rem;
    font-weight: bold;
    color: #898989;
    letter-spacing: 0;
    line-height: 1;
    margin-top: 2.4rem;
    pointer-events: none; }

.scroll-top {
  position: fixed;
  bottom: 4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 166rem;
  padding: 0 5rem;
  height: 8rem;
  pointer-events: none; }
  @media (max-width: 768px) {
    .scroll-top {
      display: none; } }
  .scroll-top__btn {
    position: absolute;
    right: 5rem;
    bottom: 0;
    width: 8rem;
    height: 8rem;
    background-color: #717171;
    border-radius: 1rem;
    cursor: pointer;
    pointer-events: auto;
    color: #fff;
    font-size: 4rem;
    transition: all 0.3s; }
    .scroll-top__btn:hover {
      opacity: 0.8; }

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 11rem;
  background-color: #fff;
  transition: all 0.3s;
  z-index: 100; }
  @media (max-width: 768px) {
    .header {
      height: 10rem;
      position: relative;
      z-index: 0; } }
  .header__container {
    height: 100%;
    padding: 0 4.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;
    max-width: 100%; }
    @media (max-width: 768px) {
      .header__container {
        justify-content: center; } }
  .header__logo {
    width: 15.4rem;
    transition: all 0.3s; }
  .header__logo-link {
    display: block; }
  .header__nav {
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    height: 4.5rem;
    transition: all 0.3s; }
    @media (max-width: 768px) {
      .header__nav {
        display: none; } }
  .header__nav-list {
    display: flex;
    height: 100%;
    margin-right: 2rem; }
  .header__nav-item {
    min-width: 10.6rem; }
  .header__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: bold;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s; }
    .header__nav-link:hover {
      color: #f0844b; }
    .header__nav-link.is-active {
      color: #f0844b; }

.header--sticky {
  height: 5.5rem; }
  @media (max-width: 768px) {
    .header--sticky {
      height: 10rem; } }
  .header--sticky .header__container {
    padding: 0 0 0 4.4rem; }
    @media (max-width: 768px) {
      .header--sticky .header__container {
        padding: 0; } }
  .header--sticky .header__nav {
    height: 100%; }
  .header--sticky .header__logo {
    width: 9rem; }
    @media (max-width: 768px) {
      .header--sticky .header__logo {
        width: 15.3rem; } }

.footer {
  width: 100%;
  padding: 8rem 0 8rem;
  color: #898989; }
  @media (max-width: 768px) {
    .footer {
      padding: 7.6rem 0 18rem; } }
  .footer__banner {
    position: relative;
    width: 100%;
    height: 11.6rem;
    background-color: #fff;
    transition: all 0.3s; }
    @media (max-width: 768px) {
      .footer__banner {
        box-shadow: none;
        height: auto; } }
  .footer__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768px) {
      .footer__container {
        flex-wrap: wrap; } }
  @media (max-width: 1200px) {
    .footer__info {
      flex-shrink: 0; } }
  .footer__info-main {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 768px) {
      .footer__info-main {
        align-items: center;
        justify-content: center; } }
  .footer__logo {
    width: 8.1rem;
    margin-right: 2rem;
    transform: translateY(0.8rem); }
    @media (max-width: 768px) {
      .footer__logo {
        transform: translateY(0);
        margin-right: 0.8rem; } }
  .footer__info-sub {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: right;
    line-height: 1;
    transform: translate(-2rem, -1rem); }
    @media (max-width: 1200px) {
      .footer__info-sub {
        font-size: 1.2rem; } }
    @media (max-width: 768px) {
      .footer__info-sub {
        font-size: 2.4rem;
        margin-top: 4rem; } }
  .footer__copyright {
    font-family: "Quicksand", "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.1em; }
    @media (max-width: 768px) {
      .footer__copyright {
        font-size: 2rem;
        margin-top: 5.6rem; } }
  .footer__tel {
    display: flex;
    align-items: center;
    font-family: "bahnschrift";
    letter-spacing: 0;
    font-size: 5.8rem;
    line-height: 1; }
    @media (max-width: 1200px) {
      .footer__tel {
        font-size: 4.8rem; } }
    @media (max-width: 768px) {
      .footer__tel {
        font-size: 5.8rem; } }
    .footer__tel::before {
      content: "";
      display: block;
      width: 2.6rem;
      height: 3.9rem;
      background-image: url("../img/icon_phone.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      transform: translateY(-0.2rem);
      margin-right: 0.8rem; }
  .footer__btn {
    width: 53.8rem;
    margin-left: 5rem; }
    @media (max-width: 1200px) {
      .footer__btn {
        width: 40%;
        margin-left: 3rem; } }
    @media (max-width: 768px) {
      .footer__btn {
        width: 53.8rem;
        margin-left: 0;
        margin-top: 5rem; } }

.section__intro {
  text-align: center; }

.section__title-en {
  display: block;
  font-family: "Quicksand", "Noto Sans JP", sans-serif;
  font-size: 3.6rem;
  font-weight: bold;
  letter-spacing: 0.1em; }
  @media (max-width: 768px) {
    .section__title-en {
      font-size: 5.4rem; } }

.section__title-jp {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #b2b2b2; }
  @media (max-width: 768px) {
    .section__title-jp {
      font-size: 2rem; } }

.section__lead-text {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 8rem;
  letter-spacing: 0.2em; }
  @media (max-width: 768px) {
    .section__lead-text {
      font-size: 2.8rem;
      line-height: 1.71;
      margin-top: 5.4rem; } }

.mv__video {
  padding: 0;
  max-width: 100%; }
  .mv__video video {
    width: 100%;
    height: auto; }

.concept {
  padding: 16rem 0 0;
  overflow: hidden; }
  @media (max-width: 768px) {
    .concept {
      padding: 10.8rem 0 0; } }
  .concept__container {
    position: relative;
    max-width: 145rem;
    margin-top: 6.8rem;
    height: 92rem; }
    @media (max-width: 768px) {
      .concept__container {
        height: auto; } }
  .concept__text-block {
    max-width: 106.4rem;
    padding-top: 4.4rem;
    margin: 0 auto;
    display: block; }
    @media (max-width: 768px) {
      .concept__text-block {
        padding-top: 0; } }
  .concept__group {
    width: 29rem; }
    @media (max-width: 768px) {
      .concept__group {
        width: 37rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0 auto; } }
  .concept__logo {
    width: 25.5rem; }
    @media (max-width: 768px) {
      .concept__logo {
        width: 36.3rem; } }
  .concept__text {
    font-size: 6rem;
    font-weight: bold;
    line-height: 1;
    color: #898989;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5.2rem;
    letter-spacing: 0.2em; }
    @media (max-width: 768px) {
      .concept__text {
        align-items: center;
        margin-top: 7rem; } }
    .concept__text span:not(:first-child) {
      margin-top: 3rem; }
  .concept__img-block {
    position: absolute;
    right: 0;
    top: 0;
    width: 108rem;
    height: 92rem; }
    @media (max-width: 768px) {
      .concept__img-block {
        position: relative;
        right: auto;
        top: auto;
        height: 103.4rem;
        width: 100%;
        margin-top: 6rem; } }
    .concept__img-block::before {
      content: "";
      position: absolute;
      width: 30.8rem;
      height: 49.1rem;
      background-image: url("../img/logo_g.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      top: 22.5rem;
      right: 31.1rem; }
      @media (max-width: 768px) {
        .concept__img-block::before {
          right: 0;
          left: 0;
          top: 26.2rem;
          margin: 0 auto; } }
  .concept__img-group {
    position: absolute;
    transition: all 0.4s cubic-bezier(0, 0, 0.61, 0.99);
    transition: opacity 200ms cubic-bezier(0.25, 0.25, 0.75, 0.75), transform 500ms cubic-bezier(0.17, 1.465, 0.415, 0.96);
    opacity: 0; }
  .concept__img-group--01 {
    right: 0;
    top: 1rem;
    width: 57.6rem;
    transform: scale(0); }
    @media (max-width: 768px) {
      .concept__img-group--01 {
        width: 45.8rem;
        top: 0;
        right: -1.5rem; } }
  .concept__img-group--02 {
    left: 0;
    bottom: 6.4rem;
    width: 52.6rem;
    transition-delay: 0.3s;
    transform: scale(0); }
    @media (max-width: 768px) {
      .concept__img-group--02 {
        width: 40.6rem;
        bottom: 0;
        left: -3.8rem; } }
  .concept__img-group--03 {
    top: 0;
    left: 17rem;
    width: 45.5rem;
    transition-delay: 0.6s;
    transform: scale(0); }
    @media (max-width: 768px) {
      .concept__img-group--03 {
        width: 35rem;
        top: 12.2rem;
        left: -3.8rem; } }
  .concept__img-group--04 {
    bottom: 0;
    right: 0;
    width: 53.8rem;
    transition-delay: 0.9s;
    transform: scale(0); }
    @media (max-width: 768px) {
      .concept__img-group--04 {
        width: 42.8rem;
        bottom: 18rem;
        right: -3rem; } }
  .concept__img-block.inview .concept__img-group {
    transform: scale(1);
    opacity: 1; }

.feature {
  padding: 13rem 0 0; }
  .feature__rows {
    margin-top: 10rem;
    padding: 0; }
    @media (max-width: 768px) {
      .feature__rows {
        margin-top: 7rem; } }
  .feature__row {
    display: flex; }
    @media (max-width: 768px) {
      .feature__row {
        flex-wrap: wrap; } }
  .feature__img, .feature__text {
    flex-basis: 50%; }
    @media (max-width: 768px) {
      .feature__img, .feature__text {
        flex-basis: 100%; } }
  .feature__text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    @media (max-width: 768px) {
      .feature__text {
        text-align: left;
        align-items: flex-start;
        padding: 5rem 4rem; } }
  .feature__title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 2.7rem; }
  .feature__paragraph {
    font-size: 1.6rem;
    line-height: 1.75; }
    @media (max-width: 768px) {
      .feature__paragraph {
        font-size: 2.8rem; } }
    .feature__paragraph + .feature__paragraph {
      margin-top: 1.75em; }
  .feature__row:nth-child(2n) {
    flex-direction: row-reverse; }

.product {
  padding: 16rem 0 0; }
  @media (max-width: 768px) {
    .product {
      padding: 3.6rem 0 0; } }
  .product__list {
    padding: 0 5rem;
    margin-top: 6.8rem;
    overflow: hidden; }
    @media (max-width: 768px) {
      .product__list {
        padding: 0 4rem;
        margin-top: 4.9rem; } }
  .product__item {
    position: relative;
    width: 100%;
    height: 50rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .product__item {
        height: 78rem; } }
  .product__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    @media (max-width: 768px) {
      .product__content {
        justify-content: space-between;
        align-items: center;
        padding: 4.5rem 0 5.1rem; } }
  .product__type {
    font-family: "Quicksand", "Noto Sans JP", sans-serif;
    font-size: 8rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 1; }
  .product__name {
    letter-spacing: 0.2em;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 1.2rem; }
    @media (max-width: 768px) {
      .product__name {
        font-size: 2.8rem;
        margin-top: 0.8rem;
        margin-bottom: auto;
        text-align: center;
        line-height: 1.428; } }
  .product__circle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 35rem;
    height: 35rem;
    background-color: #fff;
    border-radius: 50%; }
    @media (max-width: 768px) {
      .product__circle {
        margin: 0; } }
  .product__img {
    position: absolute;
    transition: all 1s cubic-bezier(0.545, 0.08, 0.52, 0.975);
    transition: all 0.6s cubic-bezier(0, 0, 0.61, 0.99);
    opacity: 0; }
  .product__btn {
    width: 19.4rem;
    height: 5rem;
    margin-top: 4.6rem; }
    @media (max-width: 768px) {
      .product__btn {
        width: 41.5rem;
        height: 8rem; } }
  .product__item:not(:first-child) {
    margin-top: 5rem; }
    @media (max-width: 768px) {
      .product__item:not(:first-child) {
        margin-top: 4rem; } }
  .product__item:nth-child(odd) {
    padding-left: 50%; }
    @media (max-width: 768px) {
      .product__item:nth-child(odd) {
        padding-left: 0; } }
    .product__item:nth-child(odd) .product__circle {
      left: 19.6%; }
    .product__item:nth-child(odd) .product__img {
      transform: translateX(-100%); }
      .product__item:nth-child(odd) .product__img.inview {
        transform: translateX(0);
        opacity: 1; }
  .product__item:nth-child(even) {
    padding-left: 21.6%; }
    @media (max-width: 768px) {
      .product__item:nth-child(even) {
        padding-left: 0; } }
    .product__item:nth-child(even) .product__circle {
      right: 19.6%; }
    .product__item:nth-child(even) .product__img {
      transform: translateX(100%); }
      .product__item:nth-child(even) .product__img.inview {
        transform: translateX(0);
        opacity: 1; }
  .product__item.product__item--01 {
    background-image: url("../img/product01_bg.png"); }
    @media (max-width: 768px) {
      .product__item.product__item--01 {
        background-image: url("../img/product01_bg_sp.png"); } }
    .product__item.product__item--01 .product__type,
    .product__item.product__item--01 .product__name {
      color: #ffb018; }
    @media (max-width: 768px) {
      .product__item.product__item--01 .product__circle {
        top: 23.5rem;
        left: 14.2rem; } }
    .product__item.product__item--01 .product__img {
      width: 36.9rem;
      top: 5.2rem;
      left: 20.73%; }
      @media (max-width: 768px) {
        .product__item.product__item--01 .product__img {
          top: 21.2rem;
          left: 15.8rem; } }
  .product__item.product__item--02 {
    background-image: url("../img/product02_bg.png"); }
    @media (max-width: 768px) {
      .product__item.product__item--02 {
        background-image: url("../img/product02_bg_sp.png"); } }
    .product__item.product__item--02 .product__type,
    .product__item.product__item--02 .product__name {
      color: #7acb52; }
    @media (max-width: 768px) {
      .product__item.product__item--02 .product__circle {
        top: 23.7rem;
        left: 22.5rem; } }
    .product__item.product__item--02 .product__img {
      width: 41.7rem;
      top: 7.6rem;
      right: 24.4%; }
      @media (max-width: 768px) {
        .product__item.product__item--02 .product__img {
          top: 23.8rem;
          right: 15.8rem; } }
  .product__item.product__item--03 {
    background-image: url("../img/product03_bg.png"); }
    @media (max-width: 768px) {
      .product__item.product__item--03 {
        background-image: url("../img/product03_bg_sp.png"); } }
    .product__item.product__item--03 .product__type,
    .product__item.product__item--03 .product__name {
      color: #28bfc1; }
    @media (max-width: 768px) {
      .product__item.product__item--03 .product__circle {
        top: 25.1rem;
        left: 16rem; } }
    .product__item.product__item--03 .product__img {
      width: 31.5rem;
      top: 9.2rem;
      left: 21.5%; }
      @media (max-width: 768px) {
        .product__item.product__item--03 .product__img {
          top: 26.8rem;
          left: 18.8rem; } }
  .product__item.product__item--04 {
    background-image: url("../img/product04_bg.png"); }
    @media (max-width: 768px) {
      .product__item.product__item--04 {
        background-image: url("../img/product04_bg_sp.png"); } }
    .product__item.product__item--04 .product__type,
    .product__item.product__item--04 .product__name {
      color: #ff7ea1; }
    @media (max-width: 768px) {
      .product__item.product__item--04 .product__circle {
        top: 25.7rem;
        left: 15.6rem; } }
    .product__item.product__item--04 .product__img {
      width: 30.4rem;
      top: 7.3rem;
      right: 23.2%; }
      @media (max-width: 768px) {
        .product__item.product__item--04 .product__img {
          top: 25.5rem;
          right: 20.8rem; } }
  .product__cards {
    margin-top: 15.6rem;
    padding: 0 5rem; }
    @media (max-width: 768px) {
      .product__cards {
        padding: 0;
        margin-top: 11.7rem; } }
  .product__card:not(:first-child) {
    margin-top: 5rem; }
    @media (max-width: 768px) {
      .product__card:not(:first-child) {
        margin-top: 4rem; } }

.product-card {
  position: relative;
  background-color: #f8f8f8;
  width: 100%;
  padding: 12.5rem 6rem 6rem; }
  @media (max-width: 768px) {
    .product-card {
      padding: 12.5rem 4rem 6.3rem; } }
  .product-card__title {
    position: absolute;
    top: 0;
    left: 0;
    width: 60rem;
    color: #fff;
    display: flex;
    flex-direction: column; }
    .product-card__title::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 13.1rem 9.2rem;
      border-color: transparent transparent #f8f8f8 transparent; }
      @media (max-width: 768px) {
        .product-card__title::before {
          border-width: 0 0 10.6rem 7.6rem; } }
  .product-card__type, .product-card__name {
    padding-left: 3.4rem;
    font-weight: bold; }
    @media (max-width: 768px) {
      .product-card__type, .product-card__name {
        padding-left: 1.6rem; } }
  .product-card__type {
    font-family: "Quicksand", "Noto Sans JP", sans-serif;
    font-size: 6.4rem;
    height: 10rem;
    background-color: #898989; }
    @media (max-width: 768px) {
      .product-card__type {
        font-size: 4.8rem;
        height: 7.5rem; } }
  .product-card__name {
    font-size: 1.8rem;
    height: 3rem;
    background-color: #f0844b; }
    @media (max-width: 768px) {
      .product-card__name {
        font-size: 2rem; } }
  .product-card__main {
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 126rem;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .product-card__main {
        flex-wrap: wrap; } }
  .product-card__slider {
    position: relative;
    max-width: 88rem;
    width: 100%;
    margin: 0 auto; }
  .product-card__usage {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto 0;
    width: 28.4rem;
    border: 2px dotted #484747;
    border-radius: 4px;
    padding: 2rem 1.5rem 1.4rem 1.9rem; }
    @media (max-width: 768px) {
      .product-card__usage {
        position: relative;
        top: auto;
        left: auto;
        transform: translateY(0);
        width: 100%;
        max-width: 100%;
        margin: 6rem 0 0;
        padding: 2.3rem 3rem; } }
  .product-card__usage-title {
    position: relative;
    font-size: 1.4rem;
    font-weight: bold;
    padding-left: 1.6rem; }
    @media (max-width: 768px) {
      .product-card__usage-title {
        font-size: 2.8rem; } }
    .product-card__usage-title::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 9px;
      border-color: transparent transparent transparent #484747; }
  .product-card__usage-text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 0.4rem; }
    @media (max-width: 768px) {
      .product-card__usage-text {
        font-size: 2.8rem;
        margin-top: 0; } }
  .product-card__modal-btn-list {
    display: flex;
    justify-content: center;
    margin: 2.2rem auto 0; }
    @media (max-width: 768px) {
      .product-card__modal-btn-list {
        justify-content: space-between;
        margin: 4.5rem auto 0; } }
  .product-card__modal-btn-item {
    color: #f0844b;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.8rem; }
    @media (max-width: 768px) {
      .product-card__modal-btn-item {
        margin: 0; } }
  .product-card__modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s; }
    .product-card__modal-btn:hover {
      background-color: #f0844b; }
      .product-card__modal-btn:hover .fas {
        color: #fff; }
    @media (max-width: 768px) {
      .product-card__modal-btn {
        width: 12.5rem;
        height: 12.5rem; } }
    .product-card__modal-btn .fas {
      color: #f0844b;
      font-size: 2rem;
      transition: all 0.3s; }
      @media (max-width: 768px) {
        .product-card__modal-btn .fas {
          font-size: 3.4rem; } }
  .product-card__modal-btn-text {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 1.4rem;
    line-height: 1; }
    @media (max-width: 768px) {
      .product-card__modal-btn-text {
        font-size: 2.8rem;
        margin-top: 2rem; } }
    .product-card__modal-btn-text--ls-sm {
      letter-spacing: 0; }
  .product-card__detail {
    max-width: 128rem;
    margin: 6rem auto 0; }
  .product-card__sub-title {
    position: relative;
    font-size: 2.4rem;
    font-weight: bold;
    padding-left: 2.1rem; }
    @media (max-width: 768px) {
      .product-card__sub-title {
        font-size: 4rem;
        padding-left: 2.3rem;
        line-height: 1.2; } }
    .product-card__sub-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-color: #f0844b;
      width: 0.6rem;
      height: 100%;
      border-radius: 12px; }
  .product-card__description {
    display: flex;
    justify-content: space-between;
    margin-top: 2.8rem; }
    @media (max-width: 768px) {
      .product-card__description {
        flex-wrap: wrap;
        margin-top: 1.6rem; } }
  .product-card__description-col {
    width: calc(50% - 1.5rem);
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .product-card__description-col {
        width: 100%; } }
  .product-card__dt, .product-card__dd {
    font-size: 1.6rem;
    line-height: 2.3125;
    padding-left: 2.4rem;
    word-break: break-all; }
    @media (max-width: 768px) {
      .product-card__dt, .product-card__dd {
        font-size: 2.8rem;
        padding-left: 0;
        line-height: 2; } }
  .product-card__dt {
    width: 17.4rem;
    flex-shrink: 0;
    font-weight: bold;
    border-right: 1px solid #484747; }
    @media (max-width: 768px) {
      .product-card__dt {
        border-right: 0;
        width: 100%;
        margin-top: 2.4rem; } }
  .product-card__dd {
    width: calc(100% - 17.4rem); }
    @media (max-width: 768px) {
      .product-card__dd {
        width: 100%; } }
  .product-card__btn {
    width: 51rem;
    height: 7.2rem;
    margin: 5.6rem auto 0; }
    @media (max-width: 768px) {
      .product-card__btn {
        width: 61rem;
        height: 10rem; } }

.slider__list {
  position: relative;
  height: 53.6rem;
  width: 53.6rem;
  margin: 0 auto; }
  .slider__list::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30.8rem;
    height: 49.1rem;
    background-image: url("../img/logo_g.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }

.slider__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s; }
  .slider__item.is-active {
    opacity: 1;
    visibility: visible; }

.slider__thumbnail {
  position: absolute;
  right: 0;
  top: 1.4rem;
  width: 10rem;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .slider__thumbnail {
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
      justify-content: space-between;
      margin-top: 3rem; } }

.slider__thumbnail-btn {
  width: 10rem;
  height: 10rem;
  border: 1px solid transparent;
  transition: all 0.3s; }
  @media (max-width: 768px) {
    .slider__thumbnail-btn {
      width: 13rem;
      height: 13rem; } }
  .slider__thumbnail-btn.is-active {
    border-color: #f0844b;
    cursor: text; }
  .slider__thumbnail-btn:not(:first-child) {
    margin-top: 1.4rem; }
    @media (max-width: 768px) {
      .slider__thumbnail-btn:not(:first-child) {
        margin-top: 0; } }
  .slider__thumbnail-btn:not(.is-active):hover {
    opacity: 0.7; }

.cover-slide {
  overflow: hidden;
  opacity: 0; }
  .cover-slide.inview {
    animation: cover-over 0.35s forwards cubic-bezier(0.8, 0, 0.5, 1);
    position: relative;
    opacity: 1 !important; }
    .cover-slide.inview::before {
      animation: cover-out 0.35s 0.35s forwards cubic-bezier(0.8, 0, 0.5, 1);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #f0844b; }

@keyframes cover-over {
  from {
    transform: scaleX(0);
    transform-origin: left; }
  to {
    transform: scaleX(1);
    transform-origin: left; } }

@keyframes cover-out {
  0% {
    transform: translateX(0); }
  99% {
    opacity: 1; }
  100% {
    transform: translateX(100%);
    opacity: 0; } }

.concept__text.inview .cover-slide {
  animation: cover-over 0.5s forwards cubic-bezier(0.8, 0, 0.5, 1);
  position: relative;
  opacity: 1 !important; }
  .concept__text.inview .cover-slide::before {
    animation: cover-out 0.5s 0.5s forwards cubic-bezier(0.8, 0, 0.5, 1);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #f0844b; }

.sp-only {
  display: none; }
  @media (max-width: 768px) {
    .sp-only {
      display: block; } }

@media (max-width: 768px) {
  .pc-only {
    display: none; } }
